$theme: #1A9AFA;
$primary: #1A9AFA;
$secondary: #000000;
$content: #4B5060;
$danger: #be1d2c;
$lightyellow :#fffbcc;
$success:#0c6638;
$white:#fff;
$light-gray:#DFE0E6;
$light:#C4C4C4;
$dark:#14122E;
$dark-medium:#1E1B43;
$ultra-light:#D2D2D2;
$lighten:#F6F6F6;
$lighten-grey:#FAFAFA;
$grey-dark:#313131;
$primary-light:#65B7F4;
$primary-lighten:#CDEEFE;
$warning:#EFB112;
$theme-colors: (
  "theme": $theme,
    "primary": $primary,
    "secondary": $secondary,
    "danger": $danger,
    "success":$success,
    "white" : $white,
    "content": $content,
    "warning":$warning
);
  
$custom-colors: (
  "light-gray" :$light-gray,
  "lightyellow" :$lightyellow,
  "light": $light,
  "dark":$dark,
  "dark-medium":$dark-medium,
  "ultra-light":$ultra-light,
  "lighten":$lighten,
  "grey-dark":$grey-dark,
  "primary-light":$primary-light,
  "primary-lighten":$primary-lighten,
  "lighten-grey":$lighten-grey,
);
$theme-colors: map-merge($theme-colors, $custom-colors);

  $base-font:'Acumin Pro', 'Open Sans', sans-serif;
  $font-light: 'Acumin-light', 'Open Sans',  sans-serif;
  $font-regular: 'Acumin-regular', 'Open Sans',  sans-serif;
  $font-medium: 'Acumin-medium', 'Open Sans',  sans-serif;
  $font-semibold: 'Acumin-semibold', 'Open Sans',  sans-serif;
  $font-bold: 'Acumin-bold', 'Open Sans',  sans-serif;

  $weights:(
    'medium': $font-medium,
    'bold': $font-bold,
    'light': $font-light,
    'regular':$font-regular,
    'semibold': $font-semibold
  );

  @each $name, $weight in $weights {
    .fw-#{$name} {
      font-family: $weight !important;
    }
  }


//==================Container Breakpoint=============//
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1200px,
  xxxl:1201px) !default;


  $spacer: 1rem;

$grid-gutter-width: 1.5rem;
$gutters: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4,
  7: $spacer * 5,
  8: $spacer * 6,
  9: $spacer * 7,
  10: $spacer * 8,
);

$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 1.8,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1760px,
  xxxl: 1761px
);
