#root {
  height: 100%;
  width: 100%;
  font-family: 'Acumin-regular';
  color: #4b5060;
  overflow: hidden;
  overflow-y: auto;
}
.custom-clickable {
  cursor: pointer !important;
}
.about-vection {
  background-image: url('../images/investor-center-overview.svg');
}
.banner-display-4 {
  background-image: url(../images/ic-bg.png);
}
.reactMarkdownDiv {
  ul {
    padding-left: 0rem;
    // list-style: circle !important;
    li {
      font-size: 1.063rem;
      margin-bottom: 0.625rem;
      // &::before {
      //   content: '\e90b';
      //   font-size: 8px;
      //   font-family: 'primary';
      //   //   position: absolute;
      //   padding-left: 0rem;
      //   padding-right: 1rem;
      //   top: 8px;
      //   left: 0;
      //   color: #252729;
      // }
    }
  }
}
.breadcrumb-item {
  &.active {
    a {
      color: #1a9afa !important;
    }
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040 !important;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.stock-chart-container {
  padding-bottom: '2rem';
}
.stock-chart-container {
  .stock-chart-info-wrapper {
    border-right: 1px solid #d3d4d8;
    padding-right: 0px !important;
    margin-bottom: 4rem;
    .element {
      padding: 0.3rem;
      color: #828691;
      label {
        padding: 0.3rem;
        color: #828691;
        font-size: 1rem;
      }
      .title {
        margin-top: 0;
        margin-bottom: 1rem;
        font-weight: bold;
        color: #000000;
        font-size: 1.4rem;
        sub {
          font-size: 15px;
          color: #828691;
          font-weight: 600;
        }
      }
    }
  }
  .market-announcement {
    padding-bottom: 4rem !important;
  }
}
.reactMarkdown p {
  // margin-bottom: 30px !important;
}
// .breadcrumb li.active {
//   padding-top: 5px;
//   // white-space: pre !important;
//   // overflow: auto !important;
// }

.home-swiper .swiper-button-next::after, .home-swiper .swiper-button-prev::after {
  display: none;
}

@media (max-width: 768px) {
  .navigation {
    width: 100%;
    margin-bottom: 10px;
  }
}
.stock-positive-price-change {
  color: #20b720 !important;
}
.stock-negative-price-change {
  color: #ff0000 !important;
}
