.system-requirement {
  background: url('../../images/solutions/products/3dframe/system.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .entry-display-3 {
    ul {
      color: $white;
      li:after {
        background-color: $primary;
      }
    }
  }
}
