.home-page {
  .hero {
    // min-height: 90vh;
    // @include media-breakpoint-down(xxl) {
    //     min-height: 100%;
    // }
    .hero-img {
      height: 19.688rem;
      overflow: hidden;
      border-radius: 15px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .swiper-pagination-bullets-dynamic {
      width: 100% !important;
      bottom: 0;
      .swiper-pagination-bullet-active {
        background: $primary !important;
      }
      .swiper-pagination-bullet {
        transform: translate(0);
      }
    }
    .swiper-pagination-bullet {
      background-color: $light;
      width: 7px;
      height: 7px;
    }
  }
}

//------------ Learn More
.learn-more {
  background: url('../../images/learn-more.png');
  background-size: cover;
  background-position: top;
  padding: 8.125rem 0;
  background-repeat: no-repeat;
  @include media-breakpoint-down(xl) {
    padding: 6.125rem 1rem;
  }
  .entry-content {
    h2 {
      font-size: 2.875rem;
      line-height: 3.45rem;
      color: $white;
      font-family: $font-bold;
    }
    p {
      font-size: 1.125rem;
      line-height: 1.75rem;
      color: $white;
      font-family: $font-light;
    }
  }
}

.home-page {
  .hero {
    background-image: url('../../images/bg.png');
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
  }
}
.technologies-silder {
  .gallery-thumbs {
    .entry-display-5 {
      h5 {
        min-height: 3.4rem;
        @include media-breakpoint-down(xl) {
          min-height: 100%;
        }
      }
    }
  }
}
