
%fontstyle {
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  @font-face {
    font-family: 'primary';
    src:  url('../../icons/primary.eot?kb8qxh');
    src:  url('../../icons/primary.eot?kb8qxh#iefix') format('embedded-opentype'),
      url('../../icons/primary.ttf?kb8qxh') format('truetype'),
      url('../../icons/primary.woff?kb8qxh') format('woff'),
      url('../../icons/primary.svg?kb8qxh#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  
  @font-face {
    font-family: 'socialmedia';
    src:  url('../../icons/socialmedia.eot?kweybl');
    src:  url('../../icons/socialmedia.eot?kweybl#iefix') format('embedded-opentype'),
      url('../../icons/socialmedia.ttf?kweybl') format('truetype'),
      url('../../icons/socialmedia.woff?kweybl') format('woff'),
      url('../../icons/socialmedia.svg?kweybl#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  @font-face {
    font-family: 'formicons';
    src:  url('../../icons/formicons.eot?kweybl');
    src:  url('../../icons/formicons.eot?kweybl#iefix') format('embedded-opentype'),
      url('../../icons/formicons.ttf?kweybl') format('truetype'),
      url('../../icons/formicons.woff?kweybl') format('woff'),
      url('../../icons/formicons.svg?kweybl#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  @font-face {
    font-family: 'comman';
    src:  url('../../icons/comman.eot?kweybl');
    src:  url('../../icons/comman.eot?kweybl#iefix') format('embedded-opentype'),
      url('../../icons/comman.ttf?kweybl') format('truetype'),
      url('../../icons/comman.woff?kweybl') format('woff'),
      url('../../icons/comman.svg?kweybl#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: 'investor-new';
    src:  url('../../icons/investor-new.eot?kweybl');
    src:  url('../../icons/investor-new.eot?kweybl#iefix') format('embedded-opentype'),
      url('../../icons/investor-new.ttf?kweybl') format('truetype'),
      url('../../icons/investor-new.woff?kweybl') format('woff'),
      url('../../icons/investor-new.svg?kweybl#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  /*-----------------Font class-------------------*/
  
  .icon-social-media {
    @extend %fontstyle;
    font-family: 'socialmedia' !important;
  }
  .icon-form {
    @extend %fontstyle;
    font-family: 'formicons' !important;
  }
  .icon-comman { 
    @extend %fontstyle;
    font-family: 'comman' !important;
  }
  .icon-investor-new { 
    @extend %fontstyle;
    font-family: 'investor-new' !important;
  }
  
  
  .icon-facebook:before {
    content: "\e900";
  }
  .icon-linkedin:before {
    content: "\e901";
  }
  .icon-twitter:before {
    content: "\e902";
  }
  .icon-envelope:before {
    content: "\e900";
  }
  .icon-phone:before {
    content: "\e901";
  }
  .icon-user:before {
    content: "\e902";
  }
  .icon-right-expand:before {
    content: "\e90a";
  }
  .icon-right-fill:before {
    content: "\e90b";
  }
  .icon-add:before {
    content: "\e900";
  }
  .icon-arrow-right:before {
    content: "\e901";
  }
  .icon-chat:before {
    content: "\e902";
  }
  .icon-envelope-fill:before {
    content: "\e903";
  }
  .icon-expand-down:before {
    content: "\e904";
  }
  .icon-location:before {
    content: "\e905";
  }
  .icon-minus:before {
    content: "\e906";
  }
  .icon-order:before {
    content: "\e907";
  }
  .icon-right:before {
    content: "\e908";
  }
  .icon-unlock:before {
    content: "\e909";
  }
  .icon-otc:before {
    content: "\e905";
  }
  .icon-cusip-number:before {
    content: "\e900";
  }
  .icon-exchange:before {
    content: "\e901";
  }
  .icon-isin-code:before {
    content: "\e902";
  }
  .icon-ratio:before {
    content: "\e903";
  }
  .icon-structure:before {
    content: "\e904";
  }
  