$breakpointsnew: (
  xs: 320px,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1366px,
  xxxl: 1760px);

$unit: "%";

@each $breakName, $breakValue in $breakpointsnew {
    @for $i from 0 through 100 {
      $selector: '';
      $space: $i;

      @if $breakName != "" {
        $selector: #{$breakName}-#{$i};
      } 

      @if $breakName != "" {
        @media (min-width: $breakValue) {
          .width-#{$selector} {
            width: #{$space + $unit};
            flex: 0 0 #{$space + $unit};
            max-width: #{$space + $unit};
            padding-right: 15px;
            padding-left: 15px;
          }
        }
      }
    }
}