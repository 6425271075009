.comman-accordion {
  .accordion-item {
    border: 1px solid rgba(18, 22, 60, 0.2);
    box-sizing: border-box;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 1.25rem;
  }
  .accordion-button {
    font-size: 1.063rem;
    font-family: $font-semibold;
    color: $dark;
    background-color: transparent;
    transition: none;
    position: relative;
    &:not(.collapsed),
    &:focus {
      box-shadow: none;
    }
    &:not(.collapsed) {
      background-color: $primary;
      color: $white;
      &::after {
        background-image: none !important;
        content: '\e906';
        font-family: 'comman' !important;
        font-size: 15px;
        position: absolute;
        right: 14px;
        top: 22px;
      }
    }
    &.collapsed {
      &::after {
        font-size: 15px;
        background-image: none !important;
        content: '\e900';
        font-family: 'comman' !important;
        position: absolute;
        right: 14px;
        top: 22px;
      }
    }
  }
  .accordion-body {
    background-color: $primary;
    color: $white;
    font-family: $font-light;
    font-size: 1.063rem;
  }
}
