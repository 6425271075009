header {
  .main-header {
    background-color: #fff;
    box-shadow: 0px 2px 10px rgba(58, 58, 58, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    transition: all 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    overflow: visible;
    @include media-breakpoint-down(xl) {
      left: 0;
    }
    .top-bar {
      display: flex;
      justify-content: flex-end;
      padding-top: 0.9rem;
      a {
        display: flex;
        align-items: center;
        color: $secondary;
        font-size: 0.938rem;
        i {
          font-size: 1.1rem;
          line-height: 1.125rem;
        }
      }
    }
    .navbar {
      justify-content: space-between;
      z-index: 9;
      padding: 0;
      position: static;
      height: 4.8rem;
      .navbar-brand {
        img {
            max-width: 9.2rem;
        }
      }
      .nav-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: stretch;

        .navbar-nav {
          display: flex;
          justify-content: flex-start;
          align-self: stretch;
          width: 100%;
          align-items: center;
          height: 100%;
          .nav-item {
            display: flex;
            align-items: center;
            align-self: stretch;
            .nav-link {
              font-size: 1.063rem;
              font-family: $font-regular;
              color: $content;
              height: 100%;
              display: flex;
              align-items: center;
              padding: 0 1.875rem;
              line-height: 1.275rem;
              position: relative;
              transition: all 0.3s ease-in-out;
              -webkit-transition: all 0.3s ease-in-out;
              &::after {
                width: 0%;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                content: '';
                height: 5px;
                opacity: 0;
                background-color: $primary;
              }
              &:hover,
              &:focus,
              &.active {
                color: $primary;
                &:after {
                  opacity: 1;
                  width: 60%;
                  transition: all 0.3s ease-in-out;
                  background-color: $primary;
                }
              }
              &.active {
                font-family: $font-semibold;
              }
            }
            &.mega-dropdown {
              .sub-menu {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                top: 100%;
                left: 0;
                width: 100%;
                background-color: #fff;
                -webkit-box-shadow: 0px 36px 35px -132px rgba(0, 0, 0, 0.08);
                -moz-box-shadow: 0px 36px 35px -132px rgba(0, 0, 0, 0.08);
                box-shadow: 0px 36px 35px -132px rgba(0, 0, 0, 0.08);
                z-index: 3;
                position: absolute;
                transition: all 0.3s ease-in-out;
                -webkit-transition: all 0.3s ease-in-out;
                opacity: 0;
                visibility: hidden;
                height: auto;
                .mega-list {
                  padding: 2.063rem 1rem;
                  .list-heading {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 1.563rem;
                    h4 {
                      font-size: 1.063rem;
                      line-height: 1.75rem;
                      color: $secondary;
                      font-family: $font-semibold;
                      margin-bottom: 0;
                    }
                    a {
                      font-size: 0.938rem;
                      line-height: 1.75rem;
                      font-family: $font-semibold;
                      color: $primary;
                    }
                  }
                  ul {
                    li {
                      &:last-child {
                        margin-bottom: 0;
                        .entry-content {
                          border: none;
                        }
                      }
                      a {
                        display: flex;
                        align-items: center;
                        .entry-icon {
                          width: 2.125rem;
                          height: 2.125rem;
                          border-radius: 50%;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        }
                        &.active {
                          .entry-content {
                            h3 {
                              color: $primary;
                            }
                          }
                        }
                        .entry-content {
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                          width: 100%;
                          margin-left: 1.188rem;
                          flex: 1;
                          padding: 1.2rem 0;
                          border-bottom: 1px solid rgba(0, 0, 0, 0.08);
                          h3 {
                            font-size: 1rem;
                            color: $dark;
                            margin-bottom: 0;
                            font-family: $font-semibold;
                          }
                          i {
                            color: #afafaf;
                            font-size: 0.8rem;
                            line-height: 1.2rem;
                          }
                        }
                      }
                    }
                  }
                  &.links {
                    background-color: #f6f6f6;
                    li {
                      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
                      .entry-icon {
                        border: none !important;
                      }
                      .entry-content {
                        padding: 0.88rem 0;
                        border: none;
                        margin-left: 0.3rem;
                      }
                      &:last-child {
                        border: none;
                      }
                    }
                  }
                }
                .bg-lighten {
                  &::after {
                    background: #f6f6f6;
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: auto;
                    z-index: -1;
                  }
                }
              }
            }
            &:hover,
            &:active,
            &:focus,
            &.nav-hover {
              .sub-menu {
                visibility: visible;
                opacity: 1;
                display: block;
              }
            }
          }
        }
      }
      .aux-component {
        display: flex;
        align-items: center;
        margin-left: auto;
        .mobile-menu-btn {
          display: block;
          background: transparent;
          border: 0;
          height: 100%;
          display: none;
          @include media-breakpoint-down(xl) {
            display: block;
          }
          .hamburger {
            width: 24px;
            height: 10px;
            -webkit-transition: 0.3s ease-in-out;
            transition: 0.3s ease-in-out;
            cursor: pointer;
            span {
              display: block;
              height: 2px;
              background-color: $primary;
              border-radius: 5px;
              margin-bottom: 6px;
              -webkit-transition: 0.3s ease-in-out;
              transition: 0.3s ease-in-out;
              position: relative;
              z-index: 5;
            }
          }
        }
      }
    }
  }
}
.mobile-menu {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  padding-top: 7rem;
  background-color: #fff;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: visibility 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  transition: visibility 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, visibility 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, visibility 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  visibility: hidden;
  z-index: 9;

  &.swipe {
    transition: all 0.3s ease-in-out;
    .main-navigation,
    .mobile-inner-menu {
      transition: all 0.3s ease-in-out;
      transform: translateX(-100%);
    }
  }

  nav {
    ul {
      li {
        border-bottom: 1px solid rgba(7, 15, 43, 0.1);
        a {
          color: $secondary;
          display: flex;
          flex: 1;
          font-size: 1.1rem;
          font-family: $font-medium;
          padding: 1rem 0rem;
          &:hover,
          &:active,
          &:focus,
          &.active {
            color: $primary;
          }
        }
      }
    }
  }

  .main-navigation {
    flex-basis: 100%;
    flex-shrink: 0;
    flex-grow: 1;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
    transform: translateX(0);
    transition: all 0.3s ease-in-out;
    overflow: auto;
  }
}
.mobile-inner-menu {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
  flex-basis: 100%;
  flex-shrink: 0;
  flex-grow: 1;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
  transform: translateX(0);
  transition: all 0.3s ease-in-out;
  overflow: auto;
  nav {
    ul {
      li {
        border-bottom: 1px solid rgba(7, 15, 43, 0.1);
        a {
          padding: 0.8rem 0rem;
          &.active {
            color: $primary;
          }
        }
      }
    }
  }
}
.mobile-menu-open {
  .hamburger {
    span {
      position: relative;
      z-index: 5;
      &:nth-child(1) {
        -webkit-transform: rotate(45deg) translate(3px, 2px);
        transform: rotate(45deg) translate(3px, 2px);
      }
      &:nth-child(2) {
        -webkit-transform: rotate(-45deg) translate(3.5px, -3px);
        transform: rotate(-45deg) translate(3.5px, -3px);
      }
      &:nth-child(3) {
        display: none !important;
      }
    }
  }
  .mobile-menu {
    visibility: visible;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}
.icon-menu-back {
  i {
    transform: rotate(-180deg);
  }
}
.menu-item {
  display: none;
  &.active {
    display: block;
  }
}
