.sidebar {
  display: flex;
  flex-direction: column;
  width: 14.5rem;
  transition: all 0.2s ease-in-out;
  transform-origin: left;
  overflow: hidden;
  z-index: 5;
  background-color: $grey-dark;
  padding-top: 3.75rem;
  padding-bottom: 2.125rem;
  height: 88.5vh;
  top: 116px;
}

.drawer-mobile {
  &.sidebar-content {
    .sidebartitle {
      margin-bottom: 1rem;
    }
  }
}

.sidebar-content {
  .sidebartitle {
    padding: 0 2.188rem;
    margin-bottom: 1rem;
    h2 {
      font-size: 1.625rem;
      line-height: 2.25rem;
      font-family: $font-bold;
      color: $white;
    }
  }
  .sidebarmenu {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .nav {
      .nav-item {
        padding: 0.4rem 0rem;
        .nav-link {
          font-size: 1.063rem;
          line-height: 1.625rem;
          color: $white;
          font-family: $font-light;
          padding: 0.54rem 1.188rem 0.54rem 2.188rem;
          position: relative;
          &.active {
            color: $primary;
            font-family: $font-bold;
            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 5px;
              height: 100%;
              background: $primary;
            }
          }
        }
      }
    }
    .accordion {
      .accordion-item {
        background-color: transparent;
        border-radius: 0;
        border: none;
        margin: 0.8rem 0;
        display: flex;
        flex-direction: column;
        a {
          font-size: 1.063rem;
          color: $white;
          font-family: $font-light;
          background-color: transparent;
          padding: 0.54rem 1.8rem 0.54rem 2.188rem;
          &.active {
            color: $primary;
            font-family: $font-semibold;
            position: relative;
            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 5px;
              height: 100%;
              background: $primary;
            }
          }
        }
        .accordion-button {
          font-size: 1.063rem;
          color: $white;
          font-family: $font-light;
          background-color: transparent;
          padding: 0.54rem 1.8rem 0.54rem 2.188rem;
          &:after {
            background-image: url('../../images/svg/expand-down.svg') !important;
            width: 1rem;
            height: 1rem;
            background-size: 1rem;
          }
          &.active {
            color: $primary;
            font-family: $font-semibold;
            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 5px;
              height: 100%;
              background: $primary;
            }
          }
          &:not(.collapsed) {
            &::after {
              background-image: url('../../images/svg/expand-down.svg') !important;
              transform: rotate(-180deg);
            }
            color: $primary;
            font-family: $font-semibold;
            background-color: transparent;
            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 5px;
              height: 100%;
              background: $primary;
            }
          }
        }
      }
      .accordion-body {
        padding: 0.2rem 1.188rem 0.54rem 2.4rem;
        background-color: transparent;
        ul {
          padding: 0;
          margin: 0;
          li {
            a {
              display: flex;
              align-items: center;
              color: $white;
              padding: 0.8rem 0;
              &.active {
                color: $primary;
                &::before {
                  display: none;
                }
              }
            }
            i {
              font-size: 10px;
              margin-right: 0.7rem;
            }
            h3 {
              font-size: 1.063rem;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
