.entry-form {
  .form-floating {
    position: relative;
    label {
      background-color: #fff;
      left: 13px;
      top: 14px;
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;
      padding: 0 0.625rem;
      font-size: 1.063rem;
      color: #6a7084;
      font-family: $font-regular;
      height: auto;
      transform: none;
      opacity: 1;
    }
    input {
      border: 1.3px solid #c1c6d5;
      box-sizing: border-box;
      border-radius: 5px;
      height: 50px;
      font-size: 1.063rem;
      color: $secondary;
      padding: 0.6rem 1rem !important;
      padding-left: 1.2rem !important;
      -webkit-appearance: none;
      &:focus,
      &:not(:placeholder-shown) {
        box-shadow: none;
        border: 2px solid $primary;
        ~ label {
          font-family: $font-semibold;
          font-size: 0.875rem;
          color: $primary;
          top: -11px;
          transition: 0.2s ease all;
          -moz-transition: 0.2s ease all;
          -webkit-transition: 0.2s ease all;
          opacity: 1;
          transform: none;
        }
      }
      &:disabled {
        background-color: transparent;
      }
    }
    .form-select {
      border: 1.3px solid #c1c6d5;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 0.6rem 1rem;
      padding-left: 1.2rem;
      height: 50px;
      font-size: 1.063rem;
      color: $secondary;
      background-position: right 1.2rem center;
      background-size: 13px 12px;
      background-image: url('../../images/svg/down.svg');
      -webkit-appearance: none;
      &:focus,
      &:not([value='']) {
        box-shadow: none;
        border: 2px solid $primary;
        &:valid {
          ~ label {
            font-family: $font-semibold;
            font-size: 0.875rem;
            color: $primary;
            top: -11px;
            transition: 0.2s ease all;
            -moz-transition: 0.2s ease all;
            -webkit-transition: 0.2s ease all;
            opacity: 1;
          }
        }
      }
    }
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      display: none;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      display: none;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      display: none;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      display: none;
    }
  }
  .form-check {
    display: flex;
    align-items: center;
    .form-check-input {
      width: 1.438rem;
      height: 1.438rem;
    }
    .form-check-label {
      margin-left: 1rem;
    }
  }
  .form-text {
    margin-top: 9px;
    color: #6a7084;
  }
  .btn {
    font-size: 1.188rem;
    padding: 1.125rem 1.16rem;
  }
}
