@import 'node_modules/bootstrap/scss/functions';

@import '../variables/variables';
@import '../variables/mixins';
@import 'common';

@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/utilities';

//Bootstrap
@import 'bootstrap';

@import 'utilities/utilities';
@import 'components/components';
@import 'pages/pages';
@import 'fonts';
@import 'post-content';
@import 'page-layout';

html,
body {
  height: 100%;
  width: 100%;
  font-family: $font-regular;
  color: $content;
  overflow: hidden;
  overflow-y: auto;
}
.page-wrapper {
  margin-top: 114px;
  background-color: $white;
  position: relative;
  @include media-breakpoint-down(xl) {
    margin-top: 77px;
  }
}
.link,
.btn-link,
a {
  text-decoration: none;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.swiper-slide {
  height: auto !important;
}
.inherit{
    color: inherit;
}
.list-unstyled{
    li{
        &::after{
            top: 10px;
            background-color: #fff !important;
        }
    }
}