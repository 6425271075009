.btn-helper{
    border: 1px solid #E2E2E2 !important;
    color: $primary;
    padding: 0.84rem 1rem;
    line-height: 1.2rem;
    font-family: $font-semibold;
    text-align: left;
    display: flex;
    align-items: center;
    &:hover{
        color: $primary;
    }
}
