%abstract-bg {
  padding: 2rem 0rem 2rem 0rem;
  z-index: 2;
  position: relative;
  background-color: $primary-lighten;
  height: 21.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @include media-breakpoint-down(md) {
    height: 15rem;
  }
  &::after {
    content: '';
    background-color: $primary-lighten;
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    right: -100vw;
    z-index: -1;
    background-size: contain;
    background-repeat: no-repeat;
    bottom: 0;
    background-position: right;
  }
  &::before {
    content: '';
    background-color: $primary-lighten;
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: -100vw;
    z-index: -1;
  }
}

.sub-heading {
  font-size: 1.063rem !important;
  line-height: 1.875rem;
  letter-spacing: 0.2em;
  font-family: $font-semibold !important;
}

%circle {
  .circle-bg {
    background-color: $theme;
    width: 48.125rem;
    height: 48.125rem;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
  }
  .entry-image {
    width: 30rem;
    height: 30rem;
    position: relative;
    z-index: 1;
    right: 2.38rem;
    border-radius: 50%;
    object-fit: cover;
    border-width: 1.8rem;
    border-style: solid;
    border-color: #fff;
    overflow: hidden;

    @include media-breakpoint-down(xl) {
      width: 30rem;
      height: 30rem;
      right: 0;
      border-width: 0px;
    }
    @include media-breakpoint-down(lg) {
      width: 25rem;
      height: 25rem;
      right: 0;
    }
    @include media-breakpoint-down(md) {
      width: 20rem;
      height: 20rem;
      right: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    &.dark {
      border-color: $grey-dark;
    }
  }
}

.right-2 {
  right: 2rem;
}
.banner-display-1 {
  @extend %circle;
  .circle-bg {
    top: -12rem;
    right: -8rem;
    @include media-breakpoint-down(xxxl) {
      top: -10rem;
      right: -15rem;
      width: 40rem;
      height: 40rem;
    }
  }
  .entry-image {
    right: 0px;
  }
}

.banner-display-2 {
  min-height: 40rem;
  display: flex;
  align-items: center;
  @extend %circle;
  .entry-thumb {
    right: 0rem;
    top: 6rem;
    @include media-breakpoint-up(xl) {
      width: auto;
    }
    @include media-breakpoint-down(xl) {
      position: relative !important;
      right: 0;
      top: 0;
    }
    .circle-bg {
      top: -15rem;
      right: -25rem;
    }
  }
}
.banner-display-3 {
  @extend %abstract-bg;
  .art {
    bottom: 0px;
    max-width: 23vw;
    @include media-breakpoint-down(xl) {
      max-width: 40vw;
    }
    &.company-banner {
      max-width: 30vw;
      img {
        width: 100%;
      }
    }
  }
}
.banner-display-4 {
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center;
  height: 25rem;
  background-repeat: no-repeat;
  .entry-display-1 {
    h1 {
      color: #fff;
    }
    .breadcrumb {
      font-size: 1rem;
      li {
        &::before {
          color: #fff;
        }
        a {
          color: #fff;
        }
        &.active {
          font-family: $font-bold;
        }
      }
    }
  }
}

.entry-display-1 {
  overflow: hidden;
  h1 {
    font-size: 3.625rem;
    line-height: 4.5rem;
    color: $secondary;
    font-family: $font-bold;
    font-weight: normal;
    @include media-breakpoint-down(xxxl) {
      font-size: 2.5rem;
      line-height: 3rem;
    }
    @include media-breakpoint-down(lg) {
      font-size: 3rem;
      line-height: 3.6rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 2.5rem;
      line-height: 3.2rem;
    }
  }
  p {
    font-size: 1.375rem;
    color: $content;
    line-height: 2rem;
    font-family: $font-light;
  }
  .entry-thumb {
    width: 100%;
    height: 25rem;
    border-radius: 0.938rem;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
  }
  .hero-thumb {
    img {
      max-width: 26rem;
      @include media-breakpoint-down(xl) {
        max-width: 20rem;
      }
    }
  }
  .entry-video-1 {
    position: relative;
    width: 100%;
    height: 25rem;
    border-radius: 15px;
    overflow: hidden;
    img {
      object-fit: cover;
      object-position: center;
    }
    .video-btn {
      background: url(../images/play.svg);
      background-size: cover;
      background-position: center;
      width: 4.875rem;
      height: 4.875rem;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      background-repeat: no-repeat;
    }
  }
  .breadcrumb {
    flex-wrap: nowrap;
    display: flex;
    font-size: 1rem;
    align-items: center;
    @include media-breakpoint-down(xl) {
      flex-wrap: wrap;
    }
    li {
      flex-shrink: 0;
      a {
        color: #000;
        font-size: 1rem;
      }
      &.active {
        font-family: $font-bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1rem;
        flex: 1;
        @include media-breakpoint-down(xl) {
          flex: none;
        }
      }
    }
  }
}
.entry-display-2 {
  h2 {
    font-size: 2.625rem;
    line-height: 3.5rem;
    color: $secondary;
    font-family: $font-bold;
    font-weight: normal;
  }
  p {
    font-size: 1.188rem;
    color: $content;
    line-height: 1.813rem;
    font-family: $font-regular;
    margin-bottom: 0px;
  }
  .entry-thumb {
    width: 100%;
    height: 21.975rem;
    border-radius: 0.938rem;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
  }
  .breadcrumb {
    flex-wrap: nowrap;
    display: flex;
    font-size: 1rem;
    align-items: center;
    @include media-breakpoint-down(xl) {
      flex-wrap: wrap;
    }
    li {
      flex-shrink: 0;
      a {
        color: #000;
        font-size: 1rem;
      }
      &.active {
        font-family: $font-bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1rem;
        flex: 1;
        @include media-breakpoint-down(xl) {
          flex: none;
        }
      }
    }
  }
}

.entry-display-3 {
  h3 {
    font-size: 2.25rem;
    line-height: 2.9rem;
    color: $secondary;
    font-family: $font-bold;
    font-weight: normal;
    @include media-breakpoint-down(lg) {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }
  p {
    font-size: 1.188rem;
    color: $content;
    line-height: 1.813rem;
    font-family: $font-regular;
    margin-bottom: 0px;
  }
  .entry-thumb {
    width: 100%;
    height: 21.975rem;
    border-radius: 0.938rem;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    li {
      position: relative;
      padding-left: 1.2rem;
      font-size: 1.063rem;
      line-height: 1.688rem;
      margin-bottom: 0.5rem;
      &:after {
        content: '';
        position: absolute;
        top: 7px;
        left: 0;
        width: 8px;
        height: 8px;
        background-color: $content;
        border-radius: 50%;
      }
    }
    &.list-arrow {
      li {
        padding-left: 2.1rem;
        font-size: 1.188rem;
        line-height: 1.875rem;
        margin-bottom: 2.2rem;
        &:after {
          content: '\e90a';
          font-family: 'comman';
          width: 100%;
          height: 100%;
          background-color: transparent;
          color: $dark;
          top: -2px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
.entry-display-4 {
  h4 {
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-family: 'Acumin-bold';
    color: #14122e;
    font-weight: normal;
  }
  p {
    font-size: 1.063rem;
    line-height: 1.5rem;
  }
  i {
    font-size: 12px;
    margin-left: 10px;
    color: #14122e;
  }
  .read-more-arrow {
    position: relative;
    &::after {
      font-family: 'comman';
      content: '\e901';
      color: #d2d2d2;
      font-size: 1.1rem;
      margin-top: 1rem;
      display: block;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      &::after {
        color: $primary;
      }
    }
  }
}
.entry-display-5 {
  h5 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #14122e;
    font-family: 'Acumin-semibold';
    margin-bottom: 0.875rem;
    font-weight: normal;
  }
  p {
    font-size: 1.063rem;
    line-height: 1.5rem;
  }
  i {
    font-size: 1rem;
    margin-left: 10px;
    color: #14122e;
    position: relative;
    left: 0;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
  }
  &:hover {
    i {
      left: 3px;
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
    }
  }
}

.block-card {
  .entry-display-3,
  .report-card {
    p {
      font-size: 1.063rem;
      line-height: 1.688rem;
    }
  }
  &.block-card-md {
    .entry-thumb {
      height: 24.063rem;
      border-radius: 0.938rem;
      overflow: hidden;
      &.entry-product {
        img {
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: contain;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
      }
    }
    .entry-video {
      position: relative;
      img {
        border-radius: 15px;
        object-fit: cover;
        object-position: center;
        width: 100%;
        max-height: 22rem;
      }
      .video-btn {
        background: url(../images/play.svg);
        background-size: cover;
        background-position: center;
        width: 4.875rem;
        height: 4.875rem;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        background-repeat: no-repeat;
      }
    }
  }
  &.block-card-lg {
    .entry-thumb {
      height: 20.313rem;
      border-radius: 0.938rem;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
      }
    }
  }
  &.block-card-sm {
    .entry-thumb {
      height: 15rem;
      border-radius: 0.938rem;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
      }
    }
  }
}

.product-list {
  .entry-icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 1.875rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .entry-content {
    h3 {
      font-size: 1.5rem;
      line-height: 1.75rem;
      font-family: $font-bold;
    }
    p {
      font-size: 1.063rem;
      line-height: 1.5rem;
    }
    .read-more {
      &::after {
        font-family: 'primary';
        content: '\e920';
        color: $ultra-light;
        font-size: 1.1rem;
        margin-top: 1.3rem;
        display: block;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}
.product-list-2 {
  .entry-thumb {
    width: 178px;
    height: 178px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 2.188rem;
    img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
  }
  .entry-content {
    h3 {
      font-size: 1.5rem;
      line-height: 1.75rem;
      font-family: $font-bold;
      color: $dark;
    }
    p {
      font-size: 1.063rem;
      line-height: 1.5rem;
    }
  }
}

.product-list-3 {
  .entry-thumb {
    width: 100%;
    height: 10.625rem;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 1.2rem;
    img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
  }
  .entry-content {
    h3 {
      font-size: 1.25rem;
      line-height: 1.75rem;
      font-family: $font-bold;
      color: $dark;
    }
    p {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
}

.card {
  &.entry-card {
    background: #fff;
    border: 1px solid rgba(85, 96, 138, 0.1);
    box-shadow: 0px 3px 3px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 0.5rem 0.5rem;
    min-height: 100%;
  }
  .card-digit {
    span {
      width: 2.813rem;
      height: 2.813rem;
      background-color: #f6f6f6;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $font-bold;
      color: $secondary;
      margin-top: 0.3rem;
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
  .entry-icon {
    span {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.entry-accordian {
  &.accordion {
    .accordion-item {
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      box-sizing: border-box;
      overflow: hidden;
      margin-bottom: 0;
      border-radius: 0;
      background-color: transparent;
    }
    .accordion-button {
      font-size: 1.25rem;
      font-family: $font-medium;
      color: $secondary;
      background-color: transparent;
      padding: 1.583rem 2rem 1.583rem 0;
      line-height: 1.4rem;
      @include media-breakpoint-down(lg) {
        line-height: 1.8rem;
      }
      &:not(.collapsed) {
        background-color: transparent;
        color: $secondary;
        &::after {
          background-image: none !important;
          content: '\e906';
          font-family: 'comman' !important;
          font-size: 15px;
          position: absolute;
          color: $primary;
          right: 10px;
          top: 26px;
        }
      }
      &.collapsed {
        &::after {
          font-size: 15px;
          background-image: none !important;
          content: '\e900';
          font-family: 'comman' !important;
          position: absolute;
          color: $primary;
          right: 10px;
          top: 26px;
        }
      }
    }
    .accordion-body {
      background-color: transparent;
      color: $content;
      font-family: $font-regular;
      font-size: 1.063rem;
      padding: 0 0 1.3rem 0rem;
    }
  }
}

$colors: (
  'block-1': #d7a62a,
  'block-2': #23a868,
  'block-3': #29bbc5,
  'block-4': #159eeb,
  'block-5': #29bbc5,
  'block-6': #eb4915,
  'block-7': #3364e0
);

.timeline {
  .card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 8px rgba(203, 203, 203, 0.25);
    border-radius: 10px;
    background-color: #fff;
    @each $name, $color in $colors {
      &.card-#{$name} {
        .entry-thumb {
          border: 2px solid $color;
        }
      }
    }
    .card-body {
      h3 {
        font-size: 1.625rem;
        line-height: 1.75rem;
        color: $secondary;
        font-family: $font-bold;
      }
      p {
        font-size: 1.125rem;
        color: $content;
        line-height: 1.688rem;
        font-family: $font-regular;
      }
      .entry-thumb {
        width: 6.875rem;
        height: 6.875rem;
        border-radius: 50%;
        overflow: hidden;
        span {
          border-radius: 50%;
          display: flex;
          border-radius: 50%;
          overflow: hidden;
          border: 5px solid #fff;
        }

        img {
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: cover;
        }
      }
    }
  }
  .timeline-path {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    height: 100%;
    width: 1px;
    background-image: url('../images/devider.png');
    background-repeat: repeat-y;
    z-index: -1;
  }

  .odd {
    .card {
      position: relative;
      @each $name, $color in $colors {
        &.card-#{$name} {
          &::before {
            content: '';
            width: 55px;
            height: 2px;
            border-style: dashed;
            border-color: $color;
            border-width: 2px;
            position: absolute;
            left: 100%;
            top: 0;
            bottom: 0;
            margin: auto;
            margin-left: 1rem;
          }
          &::after {
            content: '';
            width: 10px;
            height: 10px;
            background-color: $color;
            border-radius: 50%;
            outline: 2px solid $color;
            outline-offset: 6px;
            position: absolute;
            left: 100%;
            top: 0;
            bottom: 0;
            margin: auto;
            margin-left: 5rem;
          }
          @include media-breakpoint-down(xl) {
            &::before {
              margin-left: 1.2rem;
            }
            &::after {
              margin-left: 5.6rem;
            }
          }
          @include media-breakpoint-down(lg) {
            &::before {
              display: none;
            }
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
  .even {
    .card {
      position: relative;
      @each $name, $color in $colors {
        &.card-#{$name} {
          &::before {
            content: '';
            width: 55px;
            height: 2px;
            border-style: dashed;
            border-color: $color;
            border-width: 2px;
            position: absolute;
            right: 100%;
            top: 0;
            bottom: 0;
            margin: auto;
            margin-right: 1rem;
          }
          &::after {
            content: '';
            width: 10px;
            height: 10px;
            background-color: $color;
            border-radius: 50%;
            outline: 2px solid $color;
            outline-offset: 6px;
            position: absolute;
            right: 100%;
            top: 0;
            bottom: 0;
            margin: auto;
            margin-right: 5rem;
          }
          @include media-breakpoint-down(xl) {
            &::before {
              margin-right: 1.2rem;
            }
            &::after {
              margin-right: 5.6rem;
            }
          }
          @include media-breakpoint-down(lg) {
            &::before {
              display: none;
            }
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}

.infographic-block {
  .card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 8px rgba(203, 203, 203, 0.25);
    border-radius: 10px;
    background-color: #fff;
  }
  &.list {
    ul {
      li {
        padding: 1.45rem 1.5rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 1.125rem;
        font-family: $font-medium;
      }
    }
  }
}

.country-block {
  .card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 8px rgba(203, 203, 203, 0.25);
    border-radius: 10px;
    background-color: #fff;

    h4 {
      margin-bottom: 0px;
      font-size: 1.188rem;
      color: $secondary;
      font-family: $font-regular;
    }
  }
}

.min-h-inherit {
  min-height: inherit;
}

.map-wrapper {
  min-height: 29rem;
  .card-cover {
    padding: 4rem 2rem 2rem;
  }
  position: relative;

  .entry-content {
    h4 {
      font-size: 1.188rem;
      color: $secondary;
      font-family: $font-semibold;
      margin-bottom: 0.8rem;
    }
    h3 {
      font-size: 1.625rem;
      color: $secondary;
      font-family: $font-bold;
    }
    p {
      font-size: 1.125rem;
      line-height: 1.688rem;
    }
  }
  .card {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(187, 183, 183, 0.25);
    border-radius: 10px;
  }
}

.flotable-from {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(76, 97, 149, 0.25);
  border-radius: 20px;
  padding: 2rem 2.188rem 2.5rem 2.188rem;
  @include media-breakpoint-down(lg) {
    padding: 2rem 1.5rem 2.5rem 1.5rem;
  }
  h3 {
    font-size: 1.75rem;
    line-height: 2.375rem;
    font-family: $font-semibold;
    color: $secondary;
  }
  p {
    font-size: 1.625rem;
    line-height: 3.125rem;
    color: $secondary;
    margin-bottom: 2rem;
  }
  .form-floating {
    position: relative;
    &.form-icon {
      .form-select,
      input {
        padding-left: 3rem !important;
      }
      label {
        left: 38px;
      }
    }
    label {
      background-color: #fff;
      left: 1rem;
      top: 11px;
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;
      padding: 0 0.625rem;
      font-size: 1.063rem;
      color: #6a7084;
      font-family: $font-medium;
      height: auto;
      transform: none;
      opacity: 1;
    }
    i {
      position: absolute;
      left: 0;
      font-size: 1.063rem;
      width: 50px;
      height: 50px;
      display: flex;
      top: 1px;
      z-index: 1;
      align-items: center;
      justify-content: center;
      color: #9ca1b0;
    }
    .icon-seen {
      left: auto;
      right: 0;
    }
    input {
      border: 1.3px solid #c1c6d5;
      box-sizing: border-box;
      border-radius: 5px;
      height: 50px;
      font-size: 1.063rem;
      color: $secondary;
      padding: 0.9rem 1rem 0.6rem 1rem !important;
      padding-left: 1rem;
      -webkit-appearance: none;
      &:focus,
      &:not(:placeholder-shown) {
        box-shadow: none;
        border: 2px solid $primary;
        ~ label {
          font-family: $font-semibold;
          font-size: 0.875rem;
          color: $primary;
          top: -11px;
          transition: 0.2s ease all;
          -moz-transition: 0.2s ease all;
          -webkit-transition: 0.2s ease all;
          opacity: 1;
          transform: none;
        }
      }
    }
    .form-select {
      border: 1.3px solid #c1c6d5;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 0.9rem 1rem 0.6rem 1rem !important;
      padding-left: 1rem;
      height: 50px;
      margin-bottom: 1.25rem;
      font-size: 1.063rem;
      color: $secondary;
      background-position: right 1.2rem center;
      background-size: 13px 12px;
      -webkit-appearance: none;
      &:focus,
      &:not([value='']) {
        box-shadow: none;
        border: 2px solid $primary;
        &:valid {
          ~ label {
            font-family: $font-semibold;
            font-size: 0.875rem;
            color: $primary;
            top: -11px;
            transition: 0.2s ease all;
            -moz-transition: 0.2s ease all;
            -webkit-transition: 0.2s ease all;
            opacity: 1;
          }
        }
      }
    }
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      display: none;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      display: none;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      display: none;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      display: none;
    }
  }
  .form-check {
    display: flex;
    align-items: center;
    .form-check-input {
      width: 1.438rem;
      height: 1.438rem;
    }
    .form-check-label {
      margin-left: 1rem;
    }
  }
  .link {
    text-decoration: underline;
    color: $content;
    font-size: 1rem;
    line-height: 1.2rem;
  }
  .btn {
    font-size: 1.188rem;
    line-height: 1.425rem;
    height: 56px;
  }
  &.click-here {
    .click-box {
      background: #e9f4fc;
      border-radius: 5px;
      font-size: 1.75rem;
      line-height: 3.125rem;
      color: $primary;
      padding: 1.563rem;
      text-align: center;
      margin-bottom: 2.125rem;
      font-family: $font-semibold;
    }
    p {
      color: $content;
      font-size: 1.063rem;
      line-height: 1.688rem;
    }
  }
  @include media-breakpoint-down(sm) {
    h1 {
      font-size: 1.8rem;
      line-height: 2.125rem;
    }
    p {
      font-size: 1.3rem;
    }
  }
}

.open-position {
  background-color: $primary-lighten;
  background-image: url('../images/work-background.svg');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100%;
}

.post {
  .card {
    background: #ffffff;
    border: 1px solid rgba(85, 96, 138, 0.1);
    box-shadow: 0px 3px 3px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    min-height: 24.25rem;
    height: 100%;
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    &:hover,
    &:focus {
      transform: translateY(-5px);
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
    }
    .post-thumb {
      height: 11.25rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .post-content {
      padding: 1.25rem;
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      h3 {
        font-size: 1.25rem;
        line-height: 1.75rem;
        font-family: $font-semibold;
        color: #14122e;
      }
      .date {
        font-size: 0.938rem;
        font-family: $font-medium;
      }
    }
  }
}

.handler-menu {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  ul {
    display: flex;
    justify-content: center;
    li {
      a {
        font-size: 0.938rem;
        line-height: 1.125rem;
        color: $content;
        padding: 0 1.1rem;
        &.active {
          color: $primary;
        }
      }
    }
  }
}

.offcanvas {
  .handler-menu {
    ul {
      flex-direction: column;
      justify-content: flex-start;
      li {
        padding: 0.54rem 1.8rem 0.54rem 2.188rem;
        display: flex;
        position: relative;
        margin: 0.3rem 0rem;
        a {
          font-size: 1.063rem;
          color: #fff;
          font-family: $font-light;
          line-height: 1.8rem;
          padding: 0px;
          &.active {
            font-family: $font-bold;
            color: $primary;
            &:before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 5px;
              height: 100%;
              background: #1a9afa;
            }
          }
        }
      }
    }
  }
}

.block-slider {
  .gallery-thumbs {
    .entry-icon {
      i {
        font-size: 1.6rem;
      }
    }
    .swiper-wrapper {
      width: auto;
    }
  }
  .swiper-slide-thumb-active {
    .card {
      background-color: $primary;
      color: #fff;
      box-shadow: 0px 10px 20px rgba(92, 120, 193, 0.25);
      .entry-display-5 {
        h5 {
          color: #fff;
        }
      }
    }
  }
  &.integration {
    .gallery-thumbs {
      .entry-icon {
        span {
          width: 60px;
          height: 60px;
          background-color: $lighten-grey;
        }
      }
      .entry-display-3 {
        p {
          color: $dark;
        }
      }
    }
    .swiper-slide-thumb-active {
      .entry-icon {
        span {
          background-color: $white;
          i {
            color: $primary;
          }
        }
      }
      .entry-display-3 {
        p {
          color: $white;
        }
      }
    }
    .gallery-top {
      .entry-thumb {
        i {
          font-size: 21.5rem;
          @include media-breakpoint-down(md) {
            font-size: 16.5rem;
          }
        }
      }
    }
  }
}

.element-background {
  background-color: #313131;
  background: url('../images/bg-2.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top left;
}

.gallery-top {
  .entry-thumb {
    width: 30rem;
    height: 30rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    @include media-breakpoint-down(xl) {
      width: 25rem;
      height: 25rem;
    }
    @include media-breakpoint-down(md) {
      width: 20rem;
      height: 20rem;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

//White background Icon
$color-theme-1: #d7a62a;
$color-theme-2: #29bbc5;
$color-theme-3: #23a868;
$color-theme-4: #169eeb;
$color-theme-5: #eb4916;
$color-theme-6: #3364e0;
//lite background Icon - Product
$color-theme-7: #cd16eb;
$color-theme-8: #1a16eb;

//Dark background Icon
$darkcolor-theme-1: #f2ca61;
$darkcolor-theme-2: #53ebf4;
$darkcolor-theme-3: #3fd68d;
$darkcolor-theme-4: #46a6ff;
//Dark background Icon - Product
$darkcolor-theme-5: #ebaf16;
$darkcolor-theme-6: #169eeb;
$darkcolor-theme-7: #cd16eb;
$darkcolor-theme-8: #eb4916;
$darkcolor-theme-9: #8c8aff;
//Product Color
$darkcolor-theme-10: #acc976;
$darkcolor-theme-11: #56a4d7;
$darkcolor-theme-12: #ee8437;

$iconbucketdark: (
  /*Industries*/ 'color-1': $darkcolor-theme-1,
  'color-2': $darkcolor-theme-2,
  'color-3': $darkcolor-theme-3,
  'color-4': $darkcolor-theme-4,
  'color-5': $darkcolor-theme-5,
  'color-6': $darkcolor-theme-6,
  'color-7': $darkcolor-theme-7,
  'color-8': $darkcolor-theme-8,
  'color-9': $darkcolor-theme-9,
  'color-10': $darkcolor-theme-10,
  'color-11': $darkcolor-theme-11,
  'color-12': $darkcolor-theme-12
);
$iconbucketlite: (
  /*Industries*/ 'color-1': $color-theme-1,
  'color-2': $color-theme-2,
  'color-3': $color-theme-3,
  'color-4': $color-theme-4,
  'color-5': $color-theme-5,
  'color-6': $color-theme-6,
  'color-7': $color-theme-7,
  'color-8': $color-theme-8
);

@mixin icon-circle($dark: true) {
  @if $dark {
    @each $name, $color in $iconbucketdark {
      .entry-icon {
        margin-top: 0.4rem;
        span {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        i {
          font-size: 1.7rem;
        }
        img {
          width: auto;
          height: auto;
          max-width: 100%;
          padding: 0.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &.#{$name} {
          span {
            border: 2px solid $color;
            i {
              color: $color;
            }
          }
        }
      }
    }
  } @else {
    @each $name, $color in $iconbucketlite {
      .entry-icon {
        margin-top: 0.4rem;
        span {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        img {
          width: auto;
          height: auto;
          max-width: 100%;
          padding: 0.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        i {
          font-size: 1.7rem;
        }
        &.#{$name} {
          span {
            border: 2px solid $color;
            i {
              color: $color;
            }
          }
        }
      }
    }
  }
}

.industries {
  &.theme {
    &--dark {
      background-color: #313131;
      background: url('../images/home/industries-bg.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top left;

      @include icon-circle($dark: true);
      .entry-display-1,
      .entry-display-5 {
        h1,
        h5 {
          color: #fff;
        }
      }
      .entry-display-5 {
        p {
          color: #d2d2d2;
        }
      }
      .entry-display-1 {
        h1,
        p {
          text-align: center;
          color: #fff;
        }
      }
    }
    &--lite {
      @include icon-circle($dark: false);
    }
  }
}

.theme-lite {
  @include icon-circle($dark: false);
}
.product {
  &--theme-dark {
    @include icon-circle($dark: true);
    .entry-display-1,
    .entry-display-4 {
      h1,
      h4 {
        color: #fff;
      }
    }
    .entry-display-4 {
      h4 {
        margin-bottom: 1rem;
      }
      p {
        color: #d2d2d2;
      }
    }
    .entry-display-1 {
      h1,
      p {
        text-align: center;
        color: #fff;
      }
    }
  }
  &--theme-lite {
    @include icon-circle($dark: false);
    .entry-display-4 {
      h4 {
        margin-bottom: 1rem;
      }
    }
  }

  .entry-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        padding: 1rem;
      }
    }
  }
}

.theme {
  &--block {
    .card {
      .entry-icon {
        span {
          background: #000;
          i {
            color: #fff;
            font-size: 2rem;
          }
        }
      }
    }
  }
}

.icon-btn {
  display: inline-flex;
  align-items: center;
  i {
    font-size: 1.2rem;
  }
}

.rotate-90 {
  transform: rotate(180deg);
}
.devider {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
}

.btn-outline-primary {
  &:hover {
    color: #fff;
  }
}

.custom-scroller {
  scrollbar-width: thin;
  scrollbar-color: rgba($color: #dfe0e6, $alpha: 0.2) transparent;
}

/* total width */
.custom-scroller::-webkit-scrollbar {
  background-color: transparent;
  width: 4px;
}

/* background of the scrollbar except button or resizer */
.custom-scroller::-webkit-scrollbar-track {
  background-color: transparent;
}
.custom-scroller::-webkit-scrollbar-track:hover {
  background-color: transparent;
}

/* scrollbar itself */
.custom-scroller::-webkit-scrollbar-thumb {
  background-color: rgba($color: #dfe0e6, $alpha: 0.2);
  border-radius: 4px;
}
.custom-scroller::-webkit-scrollbar-thumb:hover {
  background-color: rgba($color: #dfe0e6, $alpha: 0.2);
}

/* set button(top and bottom of the scrollbar) */
.custom-scroller::-webkit-scrollbar-button {
  display: none;
}

.company-logo {
  h2 {
    font-size: 1.125rem;
    line-height: 1.75rem;
    text-align: center;
    letter-spacing: 0.15em;
    @include media-breakpoint-down(md) {
      font-size: 0.8rem;
    }
  }
  img {
    max-width: 8.5rem;
    filter: grayscale(100%);
  }
}

.flex-auto {
  flex: auto;
}
.flex-1 {
  flex: 1;
}
.swiper-pagination {
  .swiper-pagination-bullet-active {
    background: $primary !important;
  }
  .swiper-pagination-bullet {
    background-color: $light;
  }
}
.swiper-company-logo,
.swiper-company-logo-contact {
  .swiper-wrapper {
    -webkit-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
    animation-iteration-count: infinite !important;
    -webkit-animation-iteration-count: infinite !important;
  }
}

// .entry-thumb{
//     &.img-overlay{
//         position: relative;
//         img{
//             border-radius: 15px;
//             object-fit: cover;
//             object-position: center;
//         }
//         &::before{
//             content: '';
//             width: 100%;
//             height: 100%;
//             background: linear-gradient(180deg, #4460C2 0%, #1A82E1 100%);
//             position: absolute;
//             top: 0;
//             left: 0;
//             opacity: 0.2;
//             mix-blend-mode: overlay;
//         }
//     }
// }
.sub-link {
  // position: relative;
  // z-index: 9;
}
.pagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-size: 0.938rem;
    line-height: 1.125rem;
    color: $secondary;
    margin-bottom: 2.875rem;
  }
  .page-item {
    .page-link {
      border: none;
      font-size: 1rem;
      line-height: 1.2rem;
      color: $secondary;
      padding: 0.375rem 0.9375rem;
    }
    &.active,
    &:hover,
    &:active,
    &:focus {
      .page-link {
        color: $primary;
        background-color: transparent;
        box-shadow: none;
      }
    }
  }
  &.disabled {
    .page-link {
      color: $content;
    }
  }
}

.divider-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.card-box {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2.188rem;
  h4 {
    font-size: 2rem;
    line-height: 3rem;
    color: $dark;
    font-family: $font-bold;
  }
  p {
    font-size: 1.188rem;
    line-height: 1.813rem;
    margin-bottom: 0;
  }
}

// sidebar included pages css
.page-content {
  display: flex;
  .main-content {
    flex: 1;
    padding-left: 7rem;
    width: calc(100% - 14.54rem);
    @include media-breakpoint-down(xxxl) {
      padding-left: 4rem;
      width: calc(100% - 14.54rem);
    }
    @include media-breakpoint-down(xl) {
      padding-left: 0rem;
      width: 100%;
    }
    .entry-structure-light {
      background-color: $lighten-grey;
      position: relative;
      z-index: 1;
      &::after {
        content: '';
        position: absolute;
        left: -100vw;
        top: 0;
        background: $lighten-grey;
        width: 100vw;
        height: 100%;
        z-index: -1;
      }
      &::before {
        content: '';
        position: absolute;
        right: -100vw;
        top: 0;
        background: $lighten-grey;
        width: 100vw;
        height: 100%;
        z-index: -1;
      }
    }
  }
}
.accordion {
  .accordion-item {
    .accordion-button {
      transition: none;
      &:not(.collapsed),
      &:focus {
        box-shadow: none;
      }
    }
  }
}

.check-list {
  h5 {
    font-size: 1.125rem;
    color: $secondary;
    line-height: 1.35rem;
    font-family: $font-semibold;
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      position: relative;
      padding-left: 3rem;
      font-size: 1.063rem;
      line-height: 1.625rem;
      margin-bottom: 2rem;
      font-family: $font-medium;
      h5 {
        font-size: 1.375rem;
        line-height: 1.65rem;
        color: $dark;
        font-family: $font-bold;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &::before {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        display: inline-flex;
        background-image: url(../images/check-icon.svg);
        background-repeat: no-repeat;
        width: 28px;
        height: 28px;
        background-position: center;
        background-size: contain;
      }
    }
  }
}

.pricing-section {
  .pricing-switcher {
    .nav-pills {
      background-color: #f3f6f8;
      border-radius: 100px;
      justify-content: center;
      .nav-item {
        .nav-link {
          height: 50px;
          width: 143px;
          cursor: pointer;
          color: #6a6e7a;
          text-decoration: none;
          font-family: $font-semibold;
          font-size: 1.063rem;
          padding: 1rem;
          line-height: 1rem;
          border-radius: 100px;
          background-color: #f3f6f8;
          &.active {
            color: $white;
            text-decoration: none;
            background-color: $primary;
            box-shadow: 0px 6px 10px #c7e2f5;
          }
        }
      }
    }
    .save {
      span {
        font-size: 1.25rem;
        line-height: 1.5rem;
        color: $secondary;
        font-family: $font-semibold;
        display: block;
        margin-right: 4rem;
      }
      text-align: center;
      position: relative;
      margin-top: 1.875rem;
      &::after {
        content: '';
        position: absolute;
        top: -32px;
        right: auto;
        background: url(../images/pricing-arrow.svg);
        background-position: center;
        background-size: contain;
        width: 4rem;
        height: 3rem;
        background-repeat: no-repeat;
      }
    }
  }
  .card {
    &.pricing {
      border: 1.2px solid #eeeeee;
      border-radius: 5px;
      padding: 1.875rem 1.563rem;
      h4 {
        font-size: 1.875rem;
        line-height: 2.25rem;
        color: $secondary;
        margin-bottom: 1.75rem;
        @include media-breakpoint-down(xl) {
          margin-bottom: 1rem;
        }
      }
      .plan-price {
        position: relative;
        margin-bottom: 2rem;
        p {
          display: flex;
          align-items: center;
          position: relative;
          margin-bottom: 2.4rem;
          @include media-breakpoint-down(xl) {
            margin-bottom: 2rem;
          }
          span {
            font-size: 3rem;
            line-height: 3.6rem;
            color: $secondary;
            font-family: $font-bold;
          }
          .mo {
            color: #bfbfbf;
          }
          small {
            display: block;
            position: absolute;
            bottom: -16px;
            left: 0;
          }
        }
        .days {
          font-size: 20px;
          line-height: 24px;
          font-family: $font-medium;
        }
        .badge {
          position: absolute;
          top: -26px;
          right: 38px;
          font-size: 14px;
          padding: 0.44rem 0.71rem;
          font-family: $font-semibold;
        }
      }
      .btn-primary-light {
        background-color: #e9f4fc;
        color: $primary;
        font-size: 1.063rem;
        line-height: 1.275rem;
        font-family: $font-semibold;
        border: none;
        padding: 0.926rem 0.4rem;
        &:hover {
          background-color: #d9eefe;
        }
      }
      &.flat {
        box-shadow: 0px 0px 5px 5px rgba(164, 164, 164, 0.1);
      }
      &.professional {
        box-shadow: 0px 0px 5px 5px rgba(164, 164, 164, 0.1);
        h4 {
          color: $primary;
          font-family: $font-semibold;
        }
      }
    }
  }
  .plan-included {
    h5 {
      font-size: 1rem;
      color: $secondary;
      line-height: 1.2rem;
      font-family: $font-semibold;
      margin-bottom: 1.4rem;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        position: relative;
        padding-left: 2.6rem;
        font-size: 1.063rem;
        line-height: 1.625rem;
        margin-bottom: 1.2rem;
        &::before {
          position: absolute;
          left: 0;
          top: -2px;
          content: '';
          display: inline-flex;
          background-image: url(../images/check-icon.svg);
          background-repeat: no-repeat;
          width: 26px;
          height: 26px;
          background-position: center;
          background-size: contain;
        }
      }
    }
  }
  .start-trial {
    background: #eff8ff;
    border-radius: 10px;
    padding: 1.38rem 1.688rem;
    h5 {
      font-size: 1.875rem;
      color: $secondary;
    }
    .trial-feature {
      display: flex;
      align-items: center;
      .price {
        border-right: 1px solid #d5e7f4;
        margin-right: 1.4rem;
        padding-right: 1.6rem;
        h4 {
          font-size: 2.5rem;
          font-family: $font-bold;
          color: $secondary;
          margin: 0;
        }
        p {
          font-size: 1.25rem;
          line-height: 1.5rem;
          margin: 0;
          font-family: $font-medium;
        }
      }
      ul {
        li {
          position: relative;
          padding-left: 2rem;
          font-size: 1.063rem;
          line-height: 1.625rem;
          font-family: $font-medium;
          &:last-child {
            margin-bottom: 0;
          }
          &::before {
            position: absolute;
            left: 0;
            top: 4px;
            content: '';
            display: inline-flex;
            background-image: url(../images/check-green.svg);
            background-repeat: no-repeat;
            width: 22px;
            height: 22px;
            background-position: center;
            background-size: contain;
          }
        }
      }
    }
  }
}

.terms-condition {
  ul {
    li {
      ul {
        li {
          font-size: 1.063rem;
          line-height: 1.688rem;
          padding-left: 1.7rem;
          &:after {
            content: '\e90a';
            font-family: 'comman';
            width: 100%;
            height: 100%;
            background-color: transparent;
            color: $dark;
            top: -2px;
          }
        }
      }
    }
  }
}

.text-eclips {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.img-overlay {
  position: relative;
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #4460c2 0%, #1a82e1 100%);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.4;
    mix-blend-mode: overlay;
  }
}

video {
  object-fit: cover;
  object-position: center;
}
