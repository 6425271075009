
%background-img {
    width: 100%;
height: 100%;
background-size: contain;
background-repeat: no-repeat;
background-position: bottom left;

}

.about-vection {
    @extend %background-img;
    background-position: left bottom;
    background-size: contain;
    background-color: #CDEEFE;
}
.vr-img {
    bottom: 0;
    right: 0;
}

.market-announcement{
    .list-title{
        font-size: 0.875rem;
        color: $secondary;
        margin-bottom: 0;
        margin-right: 0.75rem;
    }
    .nav-pills{
        .nav-item{
            .nav-link{
                border-right: 2px solid #DADBDF;
                background-color: transparent;
                border-radius: 0;
                padding: 0.563rem 1.438rem;
                font-size: 0.938rem;
                line-height: 1.125rem;
                border: 2px solid #DADBDF;
                box-sizing: border-box;
                color: $content;
                border-right-width: 1px;
                &.active{
                    background-color: $primary;
                    color: $white;
                    border-color: $primary;
                }
                
            }
            &:first-child{
                .nav-link{
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }
            }
            &:last-child{
                .nav-link{
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }
            &:last-child{
                .nav-link{
                    border-right-width: 2px;
                }
            }
        }
    }
    .tab-content{
        .list-info{
            li{
                display: flex;
                align-items: center;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                padding: 1rem 0;
                @include media-breakpoint-down(lg) {
                    flex-direction: column;
                    align-items: flex-start;
                }
                h5{
                    font-size: 0.938rem;
                    margin: 0;
                    width: 6.5rem;
                    @include media-breakpoint-down(lg) {
                        margin-bottom: 0.5rem;
                    }
                }
                i{
                    color: #999B9F;
                    margin-right: 0.2rem;
                }
                p{
                    font-size: 1rem;
                    color: $primary;
                    margin-bottom: 0;
                }
                &:last-child{
                    border-bottom: none;                            
                }
            }
        }
    }
}
.director-list{
    ul{
        li{
            display: flex;
            align-items: flex-start;
            border-bottom: 1px solid rgba(7, 15, 43, 0.12);
            padding-bottom: 3.125rem;
            margin-bottom: 3.125rem;
            padding-left: 4.5rem;
            .entry-thumb{
                width: 10.625rem;
                height: 10.625rem;
                border-radius: 50%;
                background-color: $primary;
                flex-shrink: 0;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
            .entry-content{
                flex-grow: 1;
                padding-left: 8.5rem;
                h3{
                    font-size: 1.5rem;
                    line-height: 1.75rem;
                    color: $dark;
                    font-family: $font-semibold;
                }
                .role{
                    font-size: 0.938rem;
                    line-height: 1.5rem;
                    text-transform: uppercase;
                }
                .time{
                    font-size: 1rem;
                    line-height: 1.5rem;
                    font-style: italic;
                }
                .detail{
                    font-size: 1.063rem;
                    line-height: 1.688rem;
                    margin-bottom: 0;
                }
            }
        }
        &.last{
            li{
                &:last-child{
                    border: none;
                    padding-bottom: 0;
                    margin-bottom: 0;
                }
            }
        }
    }
    @include media-breakpoint-down(lg) {
        ul{
            li{
                padding-left: 0;
                .entry-content{
                    padding-left: 3rem;
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        ul{
            li{
                flex-direction: column;
                padding-left: 0;
                .entry-thumb{
                    margin-bottom: 2rem;
                }
                .entry-content{
                    padding-left: 0;
                }
            }
        }
    }
}