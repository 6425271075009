%fontstyle {
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*--------------------------------------------Core Icon---------------------------------------------------*/

$industries-font-family: 'industries' !default;
$need-font-family: 'need' !default;
$integration-font-family: 'integration' !default;
$product-font-family: 'product' !default;
$services-font-family: 'services' !default;
$investor-font-family: 'investor-center' !default;

$font-path: '../../icons' !default;

/*--Industries--*/
$icon-naval: '\e91d';
$icon-design-development-and-production-process: '\e90e';
$icon-retailers: '\e90f';
$icon-automotive: '\e90d';
$icon-aerospace: '\e910';
$icon-fashion: '\e911';
$icon-furniture: '\e912';
$icon-retail: '\e913';
$icon-healthcare: '\e914';
$icon-defence: '\e915';
$icon-media: '\e916';
$icon-betting: '\e917';
$icon-sports: '\e918';
$icon-education: '\e919';
$icon-public-sector: '\e91a';
$icon-construction: '\e91b';
$icon-machinery: '\e91c';

/*--Need--*/
$icon-design-review-need: '\e900';
$icon-training-need: '\e901';
$icon-marketing-need: '\e902';
$icon-e-commerce-need: '\e903';
$icon-remote-presentation-need: '\e904';

/*--Integration--*/
$icon-unreal-integration: '\e900';
$icon-webex-integration: '\e901';
$icon-revit-integration: '\e902';
$icon-solidwork-integration: '\e903';
$icon-grasshopper-integration: '\e904';
$icon-rhino-integration: '\e905';

/*--Product--*/
$icon-problem-solving: '\e900';
$icon-minimize-cost: '\e901';
$icon-machine-downtime: '\e902';
$icon-customer-setisfection: '\e903';
$icon-protect-idea: '\e904';
$icon-accelerate-knowledge: '\e905';
$icon-better-insights: '\e906';
$icon-better-customer-experience: '\e907';
$icon-increased-conversion-rates: '\e908';
$icon-reduced-products-return: '\e909';
$icon-showcase-virtually: '\e90a';
$icon-reduced-travel-cost: '\e90b';
$icon-amaze: '\e90c';
$icon-save-on-rent-and-staff: '\e90d';

/*--services--*/
$icon-xr-customization: '\e900';
$icon-modeling: '\e901';
$icon-metavers: '\e902';
$icon-ict-infrastructure: '\e903';
$icon-kiosk: '\e904';
$icon-casino-kiosks: '\e905';
$icon-them-parks-kiosks: '\e906';
$icon-events-kiosks: '\e907';
$icon-healthcare-kiosks: '\e908';
$icon-outdoor-kiosks: '\e909';
$icon-movies-kiosks: '\e90a';
$icon-retail-kiosks: '\e90b';
$icon-hospitaly-kiosks: '\e90c';

/*--Investor--*/
$icon-investor-center: '\e900';
$icon-global-reach: '\e901';
$icon-people: '\e902';
$icon-asx: '\e903';

@font-face {
  font-family: '#{$industries-font-family}';
  src: url('#{$font-path}/#{$industries-font-family}.eot?ei1f38');
  src: url('#{$font-path}/#{$industries-font-family}.eot?ei1f38#iefix') format('embedded-opentype'), url('#{$font-path}/#{$industries-font-family}.ttf?ei1f38') format('truetype'),
    url('#{$font-path}/#{$industries-font-family}.woff?ei1f38') format('woff'), url('#{$font-path}/#{$industries-font-family}.svg?ei1f38##{$industries-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: '#{$need-font-family}';
  src: url('#{$font-path}/#{$need-font-family}.eot?re5f45');
  src: url('#{$font-path}/#{$need-font-family}.eot?re5f45#iefix') format('embedded-opentype'), url('#{$font-path}/#{$need-font-family}.ttf?re5f45') format('truetype'),
    url('#{$font-path}/#{$need-font-family}.woff?re5f45') format('woff'), url('#{$font-path}/#{$need-font-family}.svg?re5f45##{$need-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: '#{$integration-font-family}';
  src: url('#{$font-path}/#{$integration-font-family}.eot?gutez7');
  src: url('#{$font-path}/#{$integration-font-family}.eot?gutez7#iefix') format('embedded-opentype'), url('#{$font-path}/#{$integration-font-family}.ttf?gutez7') format('truetype'),
    url('#{$font-path}/#{$integration-font-family}.woff?gutez7') format('woff'),
    url('#{$font-path}/#{$integration-font-family}.svg?gutez7##{$integration-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: '#{$product-font-family}';
  src: url('#{$font-path}/#{$product-font-family}.eot?q7q7u4');
  src: url('#{$font-path}/#{$product-font-family}.eot?q7q7u4#iefix') format('embedded-opentype'), url('#{$font-path}/#{$product-font-family}.ttf?q7q7u4') format('truetype'),
    url('#{$font-path}/#{$product-font-family}.woff?q7q7u4') format('woff'), url('#{$font-path}/#{$product-font-family}.svg?q7q7u4##{$product-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: '#{$services-font-family}';
  src: url('#{$font-path}/#{$services-font-family}.eot?fim6hh');
  src: url('#{$font-path}/#{$services-font-family}.eot?fim6hh#iefix') format('embedded-opentype'), url('#{$font-path}/#{$services-font-family}.ttf?fim6hh') format('truetype'),
    url('#{$font-path}/#{$services-font-family}.woff?fim6hh') format('woff'), url('#{$font-path}/#{$services-font-family}.svg?fim6hh##{$services-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: '#{$investor-font-family}';
  src: url('#{$font-path}/#{$investor-font-family}.eot?h4abl3');
  src: url('#{$font-path}/#{$investor-font-family}.eot?h4abl3#iefix') format('embedded-opentype'), url('#{$font-path}/#{$investor-font-family}.ttf?h4abl3') format('truetype'),
    url('#{$font-path}/#{$investor-font-family}.woff?h4abl3') format('woff'), url('#{$font-path}/#{$investor-font-family}.svg?h4abl3##{$investor-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon-industries {
  @extend %fontstyle;
  font-family: '#{$industries-font-family}' !important;
}
.icon-need {
  @extend %fontstyle;
  font-family: '#{$need-font-family}' !important;
}
.icon-integration {
  @extend %fontstyle;
  font-family: '#{$integration-font-family}' !important;
}
.icon-product {
  @extend %fontstyle;
  font-family: '#{$product-font-family}' !important;
}
.icon-services {
  @extend %fontstyle;
  font-family: '#{$services-font-family}' !important;
}
.icon-investor {
  @extend %fontstyle;
  font-family: '#{$investor-font-family}' !important;
}

/*-----------------------Icons--------------------*/

/*--Industries--*/
.icon-naval {
  &:before {
    content: $icon-naval;
  }
}
.icon-design-development-and-production-process {
  &:before {
    content: $icon-design-development-and-production-process;
  }
}
.icon-retailers {
  &:before {
    content: $icon-retailers;
  }
}
.icon-automotive {
  &:before {
    content: $icon-automotive;
  }
}
.icon-aerospace {
  &:before {
    content: $icon-aerospace;
  }
}
.icon-fashion {
  &:before {
    content: $icon-fashion;
  }
}
.icon-furniture {
  &:before {
    content: $icon-furniture;
  }
}
.icon-retail,
.icon-consumers,
.icon-e-commerce {
  &:before {
    content: $icon-retail;
  }
}
.icon-healthcare-pharma {
  &:before {
    content: $icon-healthcare;
  }
}
.icon-defence-military-law-enforcement {
  &:before {
    content: $icon-defence;
  }
}
.icon-media-communication {
  &:before {
    content: $icon-media;
  }
}
.icon-betting {
  &:before {
    content: $icon-betting;
  }
}
.icon-sports {
  &:before {
    content: $icon-sports;
  }
}
.icon-education {
  &:before {
    content: $icon-education;
  }
}
.icon-public-sector {
  &:before {
    content: $icon-public-sector;
  }
}
.icon-architecture-engineering-construction {
  &:before {
    content: $icon-construction;
  }
}
.icon-machinery-manufacturing {
  &:before {
    content: $icon-machinery;
  }
}
/*--Need--*/

.icon-design-review {
  &:before {
    content: $icon-design-review-need;
  }
}
.icon-training {
  &:before {
    content: $icon-training-need;
  }
}
.icon-marketing,
.icon-retail-experience {
  &:before {
    content: $icon-marketing-need;
  }
}
.icon-e-commerce {
  &:before {
    content: $icon-e-commerce-need;
  }
}
.icon-remote-presentations {
  &:before {
    content: $icon-remote-presentation-need;
  }
}

/* Integration */
.icon-unreal-engine {
  &:before {
    content: $icon-unreal-integration;
  }
}
.icon-webex-by-cisco {
  &:before {
    content: $icon-webex-integration;
  }
}
.icon-autodesk-revit {
  &:before {
    content: $icon-revit-integration;
  }
}
.icon-dassault-solidworks {
  &:before {
    content: $icon-solidwork-integration;
  }
}
.icon-grasshopper {
  &:before {
    content: $icon-grasshopper-integration;
  }
}
.icon-rhino {
  &:before {
    content: $icon-rhino-integration;
  }
}

/*--Product ---*/

.icon-amaze {
  &:before {
    content: $icon-amaze;
  }
}
.icon-save-on-rent-and-staff {
  &:before {
    content: $icon-save-on-rent-and-staff;
  }
}

.icon-enhance-problem-solving,
.icon-make-decisions-faster,
.icon-unique-experience {
  &:before {
    content: $icon-problem-solving;
  }
}
.icon-minimize-costs,
.icon-reduce-logistical-costs,
.icon-virtual-products {
  &:before {
    content: $icon-minimize-cost;
  }
}
.icon-reduce-machine-downtime {
  &:before {
    content: $icon-machine-downtime;
  }
}
.icon-increase-customer-satisfaction {
  &:before {
    content: $icon-customer-setisfection;
  }
}
.icon-protect-knowledge {
  &:before {
    content: $icon-protect-idea;
  }
}
.icon-accelerate-knowledge-transfer,
.icon-sell-more-anywhere {
  &:before {
    content: $icon-accelerate-knowledge;
  }
}
.icon-better-insights {
  &:before {
    content: $icon-better-insights;
  }
}
.icon-better-customer-experience,
.icon-engage-clients {
  &:before {
    content: $icon-better-customer-experience;
  }
}
.icon-increased-conversion-rates {
  &:before {
    content: $icon-increased-conversion-rates;
  }
}
.icon-reduced-products-return,
.icon-customized-products {
  &:before {
    content: $icon-reduced-products-return;
  }
}
.icon-showcase-virtually,
.icon-virtual-experience {
  &:before {
    content: $icon-showcase-virtually;
  }
}
.icon-reduce-travel-cost {
  &:before {
    content: $icon-reduced-travel-cost;
  }
}

/*--services --*/
.icon-3d-modeling-renderings {
  &:before {
    content: $icon-modeling;
  }
}
.icon-xr-customizations-white-labels {
  &:before {
    content: $icon-xr-customization;
  }
}
.icon-metaverse-web-30 {
  &:before {
    content: $icon-metavers;
  }
}
.icon-ict-infrastructure {
  &:before {
    content: $icon-ict-infrastructure;
  }
}
.icon-kiosk {
  &:before {
    content: $icon-kiosk;
  }
}
.icon-casino-kiosks {
  &:before {
    content: $icon-casino-kiosks;
  }
}
.icon-theme-parks-kiosks {
  &:before {
    content: $icon-them-parks-kiosks;
  }
}
.icon-events-kiosks {
  &:before {
    content: $icon-events-kiosks;
  }
}
.icon-healthcare-kiosks {
  &:before {
    content: $icon-healthcare-kiosks;
  }
}
.icon-outdoor-kiosks {
  &:before {
    content: $icon-outdoor-kiosks;
  }
}
.icon-movies-kiosks {
  &:before {
    content: $icon-movies-kiosks;
  }
}
.icon-retail-kiosks {
  &:before {
    content: $icon-retail-kiosks;
  }
}
.icon-hospitality-kiosks {
  &:before {
    content: $icon-hospitaly-kiosks;
  }
}

/*--Investor--*/
.icon-investor-center {
  &:before {
    content: $icon-investor-center;
  }
}
.icon-global-reach {
  &:before {
    content: $icon-global-reach;
  }
}
.icon-people {
  &:before {
    content: $icon-people;
  }
}
.icon-asx {
  &:before {
    content: $icon-asx;
  }
}
