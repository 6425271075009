.post-content {
    .card {
        .card-header {
            &.challanges {
                background-color: #D7A62A;
                color: #fff;
            }
            &.features {
                background-color: #23A868;
                color: #fff;
            }
            &.benefit {
                background-color: #169EEB;
                color: #fff;
            }
        }
        .list-group {
            li {
                display: flex;
                align-items: flex-start;
                &::before {
                    content: '\e90a';
                    font-family: 'comman';
                    background-color: transparent;
                    color: #14122E;
                    display: inline-block;
                    font-size: 0.9rem;
                    display: flex;
                    align-items: center;
                    padding-right: 0.5rem;
                }
            }
        }
    }
}

.post-image {
    max-height: 40rem;
    max-width: 100%;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.post-back {
    font-size: 1.1rem;
    i {
        transform: rotate(180deg);
        display: inline-flex;
    }
}