
$enable-negative-margins: true;
$accordion-padding-y:                     1rem;
$accordion-padding-x:                     1.25rem;
$accordion-border-color:                  rgba(18, 22, 60, 0.2);
$accordion-border-radius:                 12px;
$accordion-body-padding-y:                0 1.3rem;
$accordion-button-padding-y:              1.250rem ;
$accordion-button-padding-x:              1.3rem;
$accordion-button-active-bg:              $primary;
$accordion-button-active-color:           $white;
$accordion-icon-width:                    1.25rem;
$accordion-icon-color:                    $dark;
$accordion-icon-active-color:             $white;
$accordion-icon-transition:               transform .2s ease-in-out;
$accordion-icon-transform:                rotate(0deg);

$breadcrumb-font-size:              1.25rem;
$breadcrumb-margin-bottom:          2.5rem;
$breadcrumb-active-color:           $primary;

$border-radius:               0.313rem;
$border-radius-sm:            0.625rem;
$border-radius-lg:            0.938rem;

/*---Button----*/
$btn-font-family: 'Acumin-semibold';

$btn-padding-y-sm:            0.70rem;
$btn-padding-x-sm:            1.16rem;
$btn-font-size-sm:            1rem;
$btn-border-radius-sm:        0.313rem;


$btn-padding-y-lg:            1.2rem;
$btn-padding-x-lg:            4rem;
$btn-font-size-lg:            1.3rem;
$btn-border-radius-lg:        0.313rem;

$offcanvas-bg-color: $grey-dark;
$offcanvas-horizontal-width: 300px;
$offcanvas-padding-x: 0px;